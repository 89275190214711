import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import MainNav from './MainNav';
import OpenSideNav from './OpenSideNav';

const SideNav = ({ sideNavStatus, sideNavOpen }) => {
	const authData = useSelector((state) => state.auth);
	//const [sideNavOpen, setSideNavOpen] = useState(sideNavOpen)
	const [openNavData, setOpenNavData] = useState([]);
	const [currentlyOpen, setCurrentlyOpen] = useState(null);

	const handleSideNavOpening = (index) => {
		setOpenNavData(authData.auth.tree[index]);
		let open;
		if (currentlyOpen == index) {
			open = false;
			setCurrentlyOpen(null);
		}
		if (sideNavOpen && currentlyOpen != index) {
			setCurrentlyOpen(index);
			open = true;
		}
		if (!sideNavOpen) {
			setCurrentlyOpen(index);
			open = true;
		}
		if (authData.auth.tree[index].children.length === 0) {
			// fetchTreeReports()
			setCurrentlyOpen(index);
			open = false;
		}
		sideNavStatus(open);
		//setSideNavOpen(open)
	};
	const closeSideNav = () => {
		sideNavStatus(false);
		// setSideNavOpen(false)
	};

	const closeForceSideNav = () => {
		sideNavStatus(false);
		setCurrentlyOpen(null);
	};

	return (
		<div className="NavBar">
			<MainNav
				tree={authData.auth.tree}
				activeIndex={currentlyOpen}
				onIconClick={handleSideNavOpening}
				closeForceSideNav={closeForceSideNav}
			/>
			<OpenSideNav
				onCloseClick={closeSideNav}
				isOpen={sideNavOpen}
				data={openNavData}
			/>
		</div>
	);
};

export default SideNav;
