import React from 'react'
import { useSelector } from 'react-redux'

import { Navigate } from 'react-router-dom';
import Login from '../pages/Login';
const LoginRoute = () => {
    const authData = useSelector((state) => state.auth.auth)
    return (
        authData.loggedIn && authData.user ? <Navigate to='/' /> : <Login />
    )
}

export default LoginRoute