import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import rowDisplay from '../assets/rows-display.svg';
import tableDisplay from '../assets/table-display.svg';
import { getReports } from '../utils/routesUtils';
import ReportBox from '../components/ReportBox';
import ReportRow from '../components/ReportRow';
import { getFavoritesId } from '../utils/authUtils';
import IframePage from '../components/IframePage';
import { services } from '../services';
import { updateFavorites, updateLastReports } from '../actions/authActions';
import Loader from '../components/Loader';
import ReportsGroup from '../components/ReportsGroup';

const Reports = ({ sideNavOpen, searchVal }) => {
  const { reports } = useParams();
  const dispatch = useDispatch();
  const [currentReports, setReports] = useState([]);
  const [openOrderByDrawer, setOpenOrderByDrawer] = useState(false);
  const [iframeSrc, setIframeSrc] = useState('');
  const tree = useSelector((state) => state.auth.auth.tree);
  const favoritesReports = useSelector(
    (state) => state.auth.auth.user.favorites
  );
  const latestReports = useSelector((state) => state.auth.auth.user.latest);
  let favoritesIds = getFavoritesId(favoritesReports);

  const [title, setTitle] = useState('');
  const [tableLayout, setTableLayout] = useState(true);
  const pageReports = getReports(reports, tree);
  const [filteredCurrentReports, setFilteredCurrentReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalReports, setTotalReports] = useState([]);
  const [tags, setTags] = useState([]);
  // const filterdTotalReports = useMemo(() => {
  // 	console.log(totalReports);
  // 	return totalReports.filter(
  // 		(report) => report.name.includes(searchVal) || searchVal === '',
  // 	);
  // }, [totalReports, searchVal]);

  const orderAlphabetically = (arr) => {
    const newSort = [...currentReports];
    newSort.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    setFilteredCurrentReports(newSort);
    setOpenOrderByDrawer(false);
  };

  const orderAlphabeticallyDESC = (arr) => {
    const newSort = [...currentReports];
    newSort.sort((a, b) => {
      return -1 * a.name.localeCompare(b.name);
    });
    setFilteredCurrentReports(newSort);
    setOpenOrderByDrawer(false);
  };

  const newToOld = (arr) => {
    const newSort = [...currentReports];
    newSort.sort((a, b) => {
      return Date.parse(b.date) - Date.parse(a.date);
    });
    setFilteredCurrentReports(newSort);
    setOpenOrderByDrawer(false);
  };

  const oldToNew = (arr) => {
    const newSort = [...currentReports];
    newSort.sort((a, b) => {
      return Date.parse(a.date) - Date.parse(b.date);
    });
    setFilteredCurrentReports(newSort);
    setOpenOrderByDrawer(false);
  };

  const closeIframe = () => {
    setIframeSrc('');
  };

  useEffect(() => {
    if (searchVal) {
      let searchRes = currentReports.filter(
        (el) =>
          el.name.includes(searchVal) ||
          tags.findIndex(
            (tag) => tag.name === searchVal && el.tags.includes(tag.id)
          ) !== -1
      );
      setFilteredCurrentReports(searchRes);
      return;
    } else if (!searchVal) {
      if (reports !== 'favorites' && reports !== 'latest') {
        setTitle(pageReports.title);
        setFilteredCurrentReports(currentReports);
      } else if (reports === 'favorites') {
        setFilteredCurrentReports(favoritesReports);
      } else if (reports === 'latest') {
        setFilteredCurrentReports(latestReports);
      }
    } else {
      setReports(currentReports);
      return;
    }
  }, [reports, favoritesReports, latestReports, searchVal]);

  const handleFavorites = async (id) => {
    try {
      const favorites = await services.auth.updateFavorites(
        id,
        pageReports?.id || null
      );
      dispatch(updateFavorites(favorites));
    } catch (e) {
      console.log('favorites error', e);
    }
  };

  const fetchTreeReports = async () => {
    if (!pageReports.children.length) {
      try {
        const { treeReports, tags } = await services.reports.treeReports(
          pageReports.id
        );
        setReports(treeReports);
        setTags(tags);
      } catch (e) {
        console.log('tree fatching error', e);
      }
    }
  };

  const fetchTotalReports = async () => {
    if (searchVal.length > 0) {
      const totalReports = await services.reports.search(searchVal);
      setTotalReports(totalReports);
    } else {
      setTotalReports([]);
    }
  };

  let timer;
  function debounce(func, timeout = 300) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func();
    }, timeout);
  }

  const addToLastReports = async ({ id }) => {
    try {
      const lastReports = await services.auth.updateLastReports(id);
      dispatch(updateLastReports(lastReports));
    } catch (e) {
      console.log('updateLastReports error', e);
    }
  };

  useEffect(() => {
    if (reports !== 'favorites' && reports !== 'latest') {
      setLoading(true);
      setIframeSrc('');
      fetchTreeReports();
      setLoading(false);
    }
  }, [reports]);

  useEffect(async () => {
    if (reports === 'favorites') {
      setTitle('מועדפים');
      setReports(favoritesReports);
    } else if (reports === 'latest') {
      setTitle('אחרונים');
      setReports(latestReports);
    } else {
    }
  }, [reports]);

  useEffect(() => {
    setFilteredCurrentReports(currentReports);
  }, [currentReports]);

  useEffect(() => {
    if (!searchVal || !searchVal.length) {
      setTotalReports([]);
      return;
    }

    if (reports !== 'favorites' && reports !== 'latest') {
      debounce(fetchTotalReports, 1000);
    }
  }, [searchVal]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : iframeSrc ? (
        <IframePage
          iframeSrc={iframeSrc}
          closeIframe={closeIframe}
          sideNavOpen={sideNavOpen}
        />
      ) : (
        <div
          className={sideNavOpen ? 'reports-page shrink-page' : 'reports-page'}
        >
          <div className="reports-content">
            <div className="reports-header">
              <div className="reports-header-right">
                <p className="reports-title">{`דוחות ${title}`}</p>
                <div className="reports-title-border"></div>
                <p className="reports-amount">{`${filteredCurrentReports.length} דוחות`}</p>
              </div>
              <div className="reports-header-left">
                <div
                  className="display-wrapper"
                  onClick={() => {
                    setTableLayout(!tableLayout);
                  }}
                >
                  <img src={tableLayout ? tableDisplay : rowDisplay} alt="" />
                </div>
                <div className="filterByBox">
                  <div
                    className="filterBoxTitle"
                    onClick={() => {
                      setOpenOrderByDrawer(!openOrderByDrawer);
                    }}
                  >
                    <p>מיון לפי</p>
                    <i
                      className={
                        openOrderByDrawer
                          ? 'fa-solid fa-caret-down upsidedown'
                          : 'fa-solid fa-caret-down'
                      }
                    ></i>
                  </div>
                  {openOrderByDrawer && (
                    <div className="dropdown">
                      <div className="dropdown-item">
                        <span
                          onClick={() => {
                            orderAlphabetically(currentReports);
                          }}
                        >
                          מיון לפי א - ת
                        </span>
                      </div>
                      <div className="dropdown-item">
                        <span
                          onClick={() => {
                            orderAlphabeticallyDESC(currentReports);
                          }}
                        >
                          מיון לפי ת - א
                        </span>
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          oldToNew(currentReports);
                        }}
                      >
                        <span>מיון מהישן לחדש</span>
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          newToOld(currentReports);
                        }}
                      >
                        <span>מיון מהחדש לישן</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!filteredCurrentReports.length && <div>לא נמצאו דוחות</div>}
            {filteredCurrentReports && tableLayout ? (
              <div className="reportsWrapper-table">
                {filteredCurrentReports.map((report, index) => {
                  return (
                    <a
                      href={report.url}
                      key={index}
                      onClick={(e) => {
                        e.preventDefault();
                        setIframeSrc(report.url);
                      }}
                    >
                      <ReportBox
                        showBreadcrumbs={
                          reports === 'favorites' || reports === 'latest'
                        }
                        data={report}
                        favorite={favoritesIds.includes(report.id)}
                        handleFavorites={handleFavorites}
                      />
                    </a>
                  );
                })}
              </div>
            ) : (
              <div className="reportsWrapper-row">
                {filteredCurrentReports.map((report, index) => {
                  return (
                    <ReportRow
                      setIframeSrc={setIframeSrc}
                      key={index}
                      data={report}
                      favorite={favoritesIds.includes(report.id)}
                      handleFavorites={handleFavorites}
                    />
                  );
                })}
              </div>
            )}
            {/* <div
                            style={{
                                width: "100%",
                                height: "2px",
                                borderTop: "1px solid rgba(0, 0, 0, 0.3)",
                            }}
                        ></div> */}
            <ReportsGroup
              reports={totalReports}
              tree={tree}
              setIframeSrc={setIframeSrc}
              grid={4}
              ignoreNode={pageReports?.id}
              style={{ height: '30%' }}
              rowLayout={!tableLayout}
              favoritesIds={favoritesIds}
              handleFavorites={handleFavorites}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Reports;
