import React, { useState } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Header from '../components/Header';
import CreatePassword from '../pages/CreatePassword';
import Login from '../pages/Login';
import Main from '../pages/Main';
import ResetPassword from '../pages/ResetPassword';
import LoginRoute from './LoginRoute';
import Logout from '../pages/Logout';
import PrivateRoute from './PrivateRoute';


const AppRouter = () => {
    const [sideNavOpen, setSideNavOpen] = useState(false)
    const trackSideNav = (status) => {
        setSideNavOpen(status)
    }
    const closeSideNav = () => {
        setSideNavOpen(false)
    }
    return (
        <BrowserRouter>
            <Header closeSideNav={closeSideNav}/>
            <Routes>
                <Route path='/login' element={<LoginRoute />} />
                <Route path='/logout' element={<Logout/>} />
                <Route path="/forget-password" element={<ResetPassword />} />
                <Route path="/create-password" element={<CreatePassword />} />
                <Route path="/" element={<PrivateRoute component={Main} sideNavOpen={sideNavOpen} trackSideNav={trackSideNav} closeSideNav={closeSideNav} />} />
                <Route path="/:reports" element={<PrivateRoute component={Main} sideNavOpen={sideNavOpen} trackSideNav={trackSideNav} closeSideNav={closeSideNav} />} />
            </Routes>
        </BrowserRouter>
    )
};

export default AppRouter
