import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import SideNav from '../components/SideNav'
import HomePage from './HomePage'
import Reports from './Reports'

const Main = ({ sideNavOpen, trackSideNav, closeSideNav }) => {
    const { reports } = useParams()
    const [searchParams] = useSearchParams();
    let searchVal = searchParams.get('search');

    return (
        <div className='mainPage'>
            <SideNav sideNavStatus={trackSideNav} sideNavOpen={sideNavOpen} />
            {reports || searchVal ?
                <Reports sideNavOpen={sideNavOpen} searchVal={searchVal} />
                :
                <HomePage sideNavOpen={sideNavOpen} />
            }
        </div>
    )
}

export default Main