import React, { useEffect, useState } from 'react';
import ichilov2 from '../assets/ichilov2.png';
import headerMbi from '../assets/header-mbi2.png';
import { createSearchParams, Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Clock from './Clock';
import HeaderDate from './HeaderDate';
const Header = ({ closeSideNav }) => {
  //TODO HEADER DISSAPEAR ON LOGIN
  const authData = useSelector((state) => state.auth.auth);
  const path = window.location.pathname;
  const [searchVal, setSearchVal] = useState('');
  // const [inLogin, setInLogin] = useState(!(!!authData.name))
  const location = useLocation();

  const navigate = useNavigate();
  const handleSearch = (value) => {
    if (value.length > 0) {
      navigate({
        pathname: location.pathname,
        search: createSearchParams({
          search: value,
        }).toString(),
      });
    } else {
      navigate({
        pathname: location.pathname,
        search: '',
      });
    }
  };

  const toShowSearch = () => {
    let toShow = false;
    if (window.location.href.includes('?search')) {
      toShow = true;
    }
    if (path !== '/' && path !== '') {
      toShow = true;
    }
    return toShow;
  };

  // useEffect(() => {

  // }, [inLogin, authData])

  useEffect(() => {
    setSearchVal('');
  }, [navigate]);

  return authData.loggedIn && authData.user && !authData.user.is_first_login ? (
    <div className="header">
      <div className="header-right">
        <Link
          to="/"
          reload
          className="header-logos-wrapper"
          onClick={() => {
            if (path === '/') window.location.reload();
            console.log('clock');
            closeSideNav();
          }}
        >
          <div className="logo-div">
            <img src={headerMbi} />
          </div>
        </Link>
        {toShowSearch() && (
          <div className="header-search">
            <i className="fa-solid fa-magnifying-glass magnify"></i>
            <input
              className="header-search-input"
              placeholder="חיפוש"
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value);
                handleSearch(e.target.value);
              }}
            />
          </div>
        )}
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center', gap: 20, height: 80 }}
      >
        <div style={{ position: 'relative', textAlign: 'center' }}>
          <div>{authData.user.full_name_heb ?? ' '}</div>
          <div>
            <Link to="/logout">יציאה</Link>
          </div>
        </div>
        <div className="header-left">
          <div className="header-time">
            <Clock />
          </div>
          <div className="header-date">
            <HeaderDate />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Header;
