import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import mbi from '../assets/deflog.webp';
import Loader from '../components/Loader';
import { services } from '../services';

const ResetPassword = () => {
	const [email, setEmail] = useState('');
	const [errorEmail, setErrorEmail] = useState(false);
	const [errMsg, setErrMsg] = useState('');
	const [mailSent, setMailSent] = useState(false);
	const [loading, setLoaidng] = useState(false);
	const navigate = useNavigate();

	const loginHandler = async (e) => {
		e.preventDefault();
		if (email === '') {
			setErrorEmail(true);
			setErrMsg('חובה למלא מייל תיקני');
			return;
		}
		try {
			setErrMsg('');
			setErrorEmail(false);
			setLoaidng(true);
			const result = await services.auth.forgotPassword(email);

			if (!result) {
				setErrorEmail(true);
				setErrMsg('מייל לא קיים במערכת');
				return;
			}
			setMailSent(result);
			if (result) {
				setTimeout(() => {
					navigate('/login');
				}, 5000);
			}
		} catch (err) {
			setErrorEmail(true);
			setErrMsg('מייל לא קיים במערכת');
		} finally {
			setLoaidng(false);
		}
	};
	return (
		<div className="LoginPage">
			<div className="loginContent">
				<div className="login-logo-wrapper">
					<img src={mbi} alt="mbi" />
				</div>
				<form
					className="login-box"
					onSubmit={(e) => {
						loginHandler(e);
					}}
				>
					<p className="login-header">יצירת סיסמה חדשה</p>
					<div className="inputs-wrapper">
						<div
							style={{
								backgroundColor: email ? 'white' : '#f5f5f5 ',
							}}
							className={'input-wrapper name-wrapper'}
						>
							<input
								type="email"
								className="login-input"
								placeholder="מייל"
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
								}}
							/>
						</div>
						{errorEmail && (
							<p className="errorText name-error">{errMsg}</p>
						)}
					</div>
					{loading ? (
						<Loader />
					) : (
						<button type="submit" className="login-btn">
							שלח סיסמה למייל
						</button>
					)}
					{mailSent && <p>סיסמה נשלחה למייל!</p>}
				</form>
			</div>
		</div>
	);
};

export default ResetPassword;
