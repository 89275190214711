import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Navigate, useNavigate } from 'react-router-dom';
const PrivateRoute = ({ component: RouteComponent, sideNavOpen, trackSideNav, closeSideNav }) => {
    const authData = useSelector((state) => state.auth.auth)
    const navigate = useNavigate()

    useEffect(() => {
        if (!!authData.user) {
            const user = authData.user;
            if (!!user.is_first_login) {
                console.log(user);
                navigate("/create-password")
            }
        }
    }, [authData.user])
    return (
        authData.loggedIn && authData.user ? <RouteComponent sideNavOpen={sideNavOpen} trackSideNav={trackSideNav} closeSideNav={closeSideNav} /> : <Navigate to='/login' />
    );
}

export default PrivateRoute