export const UPDATE_FAVORITES = 'UPDATE_FAVORITES'
export const LOGIN = 'LOGIN'
export const UPDATE_FIRST_LOGIN = 'UPDATE_FIRST_LOGIN'
export const LOGOUT = 'LOGOUT'
export const UPDATE_LAST_REPORTS = 'UPDATE_LAST_REPORTS'

export const updateFavorites = (favorites) => ({
    type: UPDATE_FAVORITES,
    favorites
})

export const updateLastReports = (lastReports) => ({
    type: UPDATE_LAST_REPORTS,
    lastReports
})

export const loginAction = (loggedIn, user, tree) => ({
    type: LOGIN,
    loggedIn, user, tree
})

export const logoutAction = () => ({
    type: LOGOUT,
})

export const updateFirstLoginAction = (isFirstLogin) => ({
    type: UPDATE_FIRST_LOGIN,
    payload: isFirstLogin
})