import React, {useEffect, useState} from 'react';
import Moment from "react-moment";
import moment from "moment";

const Clock = () => {
    const [time, setTime] = useState(Date.now());
    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
          clearInterval(interval);
        };
      }, []);
      // console.log(moment(time).format("LT"));


    return (
        <Moment locale="he" format={"HH:mm"} className="clock">
          {time}
        </Moment>
    )
}

export default Clock