import Api from './Api';

class ReportService {
	async reports(page, limit, query) {
		try {
			const {
				data: {
					data: { reports },
				},
			} = await Api.get('reports', {
				page: page,
				limit: limit,
				query: query,
			});

			return reports;
		} catch (e) {
			return false;
		}
	}

	async search(query) {
		try {
			const {
				data: {
					data: { reports },
				},
			} = await Api.get('reports/search', {
				query: query,
			});

			return reports;
		} catch (e) {
			return false;
		}
	}

	async treeReports(id) {
		try {
			const {
				data: {
					data: { reports, tags },
				},
			} = await Api.get(`tree/${id}/reports`, {
				id: id,
			});

			return { treeReports: reports, tags };
		} catch (e) {
			return false;
		}
	}
}

export default new ReportService();
