import React from 'react';
import { useMemo } from 'react';
import ReportBox from './ReportBox';
import ReportRow from './ReportRow';

const ReportsGroup = ({
  reports,
  tree,
  setIframeSrc,
  grid,
  overflow,
  ignoreNode,
  style,
  rowLayout,
  favoritesIds,
  handleFavorites,
}) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        ...(overflow && { overflowY: 'auto' }),
        // padding: "20px",
        ...style,
        padding: '0 20px',
      }}
      className="report-group"
    >
      {tree.map((node) => (
        <Group
          key={node.id}
          reports={reports}
          tree={node}
          setIframeSrc={setIframeSrc}
          grid={grid}
          ignoreNode={ignoreNode}
          rowLayout={rowLayout}
          favoritesIds={favoritesIds}
          handleFavorites={handleFavorites}
        />
      ))}
    </div>
  );
};

const Group = ({
  tree,
  reports,
  setIframeSrc,
  grid,
  ignoreNode,
  rowLayout,
  favoritesIds,
  handleFavorites,
}) => {
  const table = {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `repeat(${grid || 3}, 1fr)`,
    gap: '3%',
  };

  const row = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 0.5vw 0 1vw',
  };

  const searchedReports = useMemo(() => {
    return reports.filter((report) => tree.reports.includes(report.id));
  }, [reports]);

  if (tree.children.length === 0) {
    if (tree.reports.length === 0 || searchedReports.length === 0) {
      return null;
    }

    if (ignoreNode === tree.id) {
      return null;
    }

    return (
      <>
        <h2
          style={{
            // marginRight: "12px",
            marginTop: '60px',
            fontWeight: 400,
          }}
        >
          {tree.title}
        </h2>
        <div style={rowLayout ? row : table}>
          {searchedReports.map((report) => (
            <div
              key={report.id}
              onClick={() => {
                setIframeSrc(report.url);
              }}
            >
              {!!rowLayout ? (
                <ReportRow
                  setIframeSrc={setIframeSrc}
                  data={{ ...report, breadcrumbs: tree.breadcrumbs }}
                  favorite={favoritesIds.includes(report.id)}
                  handleFavorites={handleFavorites}
                />
              ) : (
                <ReportBox
                  data={{ ...report, breadcrumbs: tree.breadcrumbs }}
                  key={report.id}
                  favorite={favoritesIds.includes(report.id)}
                  handleFavorites={handleFavorites}
                />
              )}
            </div>
          ))}
        </div>
      </>
    );
  }
  return (
    <>
      {tree.children.map((node) => (
        <Group
          key={node.title}
          reports={reports}
          setIframeSrc={setIframeSrc}
          tree={node}
          grid={grid}
          ignoreNode={ignoreNode}
          rowLayout={rowLayout}
          favoritesIds={favoritesIds}
          handleFavorites={handleFavorites}
        />
      ))}
    </>
  );
};

export default ReportsGroup;
