import axios from 'axios';

class Api {
    constructor() {
        this.client = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            timeout: 2500,
        });

        this.client.interceptors.request.use(
            (config) => {
                const headers = {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                };

                if (this.token) {
                    headers['Authorization'] = `Bearer ${this.token}`;
                }

                config.headers = headers;

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    get token() {
        return localStorage.getItem('token');
    }

    set token(token) {
        localStorage.setItem('token', token);
    }

    removeToken() {
        localStorage.removeItem('token');
    }

    get(url, params = {}) {
        return this.client.get(url, { params: params });
    }

    post(url, data) {
        return this.client.post(url, data);
    }

    delete(url, data) {
        return this.client.delete(url, data);
    }

    patch(url, data) {
        return this.client.patch(url, data);
    }

    put(url, data) {
        return this.client.put(url, data);
    }

    files(method, url, data) {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        });

        return this.client({
            method: method,
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}

export default new Api();
