import Api from './Api';

class AuthService {
  get hasToken() {
    return Api.token && Api.token.length > 0;
  }

  setToken(token) {
    Api.token = token;
  }

  removeToken() {
    Api.removeToken();
  }

  async login(username, password) {
    try {
      const {
        data: {
          data: { token },
        },
      } = await Api.post('auth/login', {
        username,
        password,
      });

      if (token) {
        return token;
      }

      throw new Error();
    } catch (e) {
      throw e;
    }
  }

  async forgotPassword(email) {
    try {
      const {
        data: {
          data: { success },
        },
      } = await Api.post('auth/forgot-password', {
        email,
      });
      return success;
    } catch (e) {
      throw e;
    }
  }

  async createPassword(password) {
    try {
      const {
        data: {
          data: { success },
        },
      } = await Api.post('users/reset-password', { password });
      return success;
    } catch (e) {
      throw e;
    }
  }

  async user() {
    try {
      const { data } = await Api.get('auth/user');

      return data;
    } catch (e) {
      return false;
    }
  }

  async updateFavorites(report_id, level_id = null) {
    try {
      const {
        data: {
          data: { favorites },
        },
      } = await Api.post(`favorites`, { report_id, level_id });

      if (favorites) {
        return favorites;
      }

      throw new Error();
    } catch (e) {
      console.log('favorites error', e);
      throw e;
    }
  }

  async updateLastReports(report_id) {
    try {
      const {
        data: {
          data: { lastReports },
        },
      } = await Api.post(`reports/add-to-last-reports/${report_id}`);

      if (lastReports) {
        return lastReports;
      }

      throw new Error();
    } catch (error) {
      console.log('lastReports error', error);
      throw error;
    }
  }
}

export default new AuthService();
