import React from 'react';
import { useNavigate, useParams } from 'react-router';
import NavIcon from './NavIcon';
import favoritesIcon from '../assets/favorites.png';
import { Link } from 'react-router-dom';

const MainNav = ({ tree, onIconClick, closeForceSideNav, activeIndex }) => {
  const navigate = useNavigate();
  const { reports } = useParams();

  return (
    <div className="sideNav">
      {tree.map((icon, index) => (
        <NavIcon
          data={icon}
          active={activeIndex == index}
          key={index}
          onIconClick={onIconClick}
          index={index}
        />
      ))}
      <Link onClick={closeForceSideNav} className="side-link" to={`/favorites`}>
        <div
          className={`navLinkWrapper ${
            !activeIndex && reports === 'favorites' ? 'active' : ''
          }`}
        >
          <img src={favoritesIcon} />
          <p className="iconTitle">דוחות מועדפים</p>
        </div>
      </Link>
      <br/>
    </div>
  );
};

export default MainNav;
