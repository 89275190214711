import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginAction } from '../actions/authActions';
// import mbi from '../assets/deflog.webp';
import mbi from '../assets/header-mbi2.png';
import openEye from '../assets/openEye.svg';
import Loader from '../components/Loader';
import { services } from '../services';

const Login = () => {
  const dispatch = useDispatch();
  const [eyeOpen, setOpenEye] = useState(false);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorLogin, setErrorLogin] = useState('');

  const LoginHandler = async (e) => {
    if (loading) {
      return;
    }

    e.preventDefault();
    setLoading(true);

    if (userName && password) {
      try {
        const token = await services.auth.login(userName, password);
        services.auth.setToken(token);

        const user = await services.auth.user(token);
        dispatch(loginAction(true, user.user, user.tree));
      } catch (e) {
        setErrorLogin('ההתחברות נכשלה, סיסמה או אימייל לא נכונים');
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const checkUser = async () => {
    setLoading(true);
    const user = await services.auth.user();

    if (!user) {
      services.auth.removeToken();
      return;
    }
    dispatch(loginAction(true, user.user, user.tree));
    setLoading(false);
  };

  useEffect(() => {
    let isCheck = true;
    if (services.auth.hasToken) {
      checkUser();
    }
    return () => (isCheck = false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="LoginPage">
          <div className="loginContent">
            <div className="login-logo-wrapper">
              <img src={mbi} alt="mbi" />
              <h1>Unlock the power of data - Dive in now!</h1>
            </div>
            <form
              className="login-box"
              onSubmit={(e) => {
                LoginHandler(e);
              }}
            >
              <p className="login-header">ברוכים הבאים</p>
              <div className="inputs-wrapper">
                <div
                  style={{
                    backgroundColor: userName ? 'white' : '#f5f5f5 ',
                  }}
                  className={
                    errorLogin
                      ? 'input-wrapper name-wrapper error-input'
                      : 'input-wrapper name-wrapper'
                  }
                >
                  <input
                    type="name"
                    className="login-input"
                    placeholder="שם משתמש"
                    value={userName}
                    onChange={(e) => {
                      setErrorLogin('');
                      setUserName(e.target.value);
                    }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: password ? 'white' : '#f5f5f5 ',
                  }}
                  className={
                    errorLogin
                      ? 'input-wrapper password-wrapper error-input'
                      : 'input-wrapper password-wrapper'
                  }
                >
                  <input
                    type={eyeOpen ? 'text' : 'password'}
                    className="login-input"
                    placeholder="סיסמא"
                    value={password}
                    onChange={(e) => {
                      setErrorLogin('');
                      setPassword(e.target.value);
                    }}
                  />
                  <img
                    onClick={() => {
                      setOpenEye(!eyeOpen);
                    }}
                    className={eyeOpen ? 'openEye' : 'closeEye'}
                    src={openEye}
                    alt=""
                  />
                </div>
                {errorLogin && <p className="errorText">{errorLogin}</p>}
              </div>
              <button type="submit" className="login-btn">
                להתחבר
              </button>
              <Link to="/forget-password">שכחתי סיסמה</Link>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
