import React, { useEffect, useMemo, useState } from 'react';
import mbi3 from '../assets/mbi.png';
import graphs from '../assets/graphs.png';
import { useSelector, useDispatch } from 'react-redux';
import ReportBox from '../components/ReportBox';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';
import { services } from '../services';
import { getFavoritesId } from '../utils/authUtils';
import { updateFavorites } from '../actions/authActions';
import IframePage from '../components/IframePage';
import { DebounceInput } from 'react-debounce-input';
import ReportsGroup from '../components/ReportsGroup';
import AccordingToTime from '../components/AccordingToTime';

const HomePage = ({ sideNavOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchVal, setSearchVal] = useState('');
  const [birtdayCard, setBirtdayCard] = useState(false);
  const authData = useSelector((state) => state.auth);
  const favoritesReports = authData.auth.user.favorites.slice(0, 3);
  const latestReports = authData.auth.user.latest.slice(0, 3);
  const [searchReports, setSearchReports] = useState(false);
  let favoritesIds = getFavoritesId(authData.auth.user.favorites);
  const [iframeSrc, setIframeSrc] = useState('');
  const tree = useSelector((state) => state.auth.auth.tree);

  // useEffect(() => {
  // 	favoritesReports.map(() => {

  // 	})
  // },[authData.auth.user.favorites])

  const handleSearchReports = async (value) => {
    setSearchVal(value);

    try {
      const reports = await services.reports.search(value);
      setSearchReports(reports);
    } catch (e) {
      console.log('tree fatching error', e);
    }
  };

  const handleFavorites = async (id) => {
    try {
      const favorites = await services.auth.updateFavorites(id);
      dispatch(updateFavorites(favorites));
    } catch (e) {
      console.log('favorites error', e);
    }
  };

  const closeIframe = () => {
    setIframeSrc('');
  };

  const changeHandler = async (value) => {
    await handleSearchReports(value);
  };

  return (
    <>
      {iframeSrc ? (
        <IframePage iframeSrc={iframeSrc} closeIframe={closeIframe} />
      ) : (
        <div className={`homePage ${sideNavOpen ? 'shrink-page' : ''}`}>
          <div className="home-right">
            <div className="bottom-graps">
              <img src={graphs} alt="" />
            </div>
            <div className="home-right-content">
              <div className="userName">
                <AccordingToTime />
                <p>{authData.auth.user.full_name_heb}</p>
              </div>
              <div className="home-search-wrapper">
                <i className="fa-solid fa-magnifying-glass magnify"></i>

                <DebounceInput
                  className="home-serach-input"
                  placeholder="חפשו דוחות..."
                  minLength={1}
                  debounceTimeout={300}
                  onChange={(event) => changeHandler(event.target.value)}
                />
              </div>
              <div className="home-links">
                <p className="link-title">
                  לעזרה ניתן לפנות אלינו באמצעות מערכת הקריאות
                </p>
                <a
                  className="home-link"
                  target="_blank"
                  href="http://sysaid-srv/servicePortal"
                >
                  http://sysaid-srv/servicePortal
                </a>
              </div>
            </div>
          </div>
          <div className="home-left">
            {searchVal.length > 0 && (
              <>
                {searchReports && searchReports.length > 0 ? (
                  // searchReports.map((report, index) => {
                  // 	return (
                  // 		<div
                  // 			key={report.id}
                  // 			onClick={() => {
                  // 				setIframeSrc(
                  // 					report.url,
                  // 				);
                  // 			}}
                  // 		>
                  // 			<ReportBox
                  // 				data={report}
                  // 				favorite={favoritesIds.includes(
                  // 					report.id,
                  // 				)}
                  // 				key={index}
                  // 				handleFavorites={
                  // 					handleFavorites
                  // 				}
                  // 			/>
                  // 		</div>
                  // 	);
                  // })
                  <ReportsGroup
                    reports={searchReports}
                    tree={tree}
                    setIframeSrc={setIframeSrc}
                    overflow
                    favoritesIds={favoritesIds}
                    handleFavorites={handleFavorites}
                  />
                ) : (
                  <p className="noResults">אין תוצאות</p>
                )}
              </>
            )}
            {!searchVal.length && (
              <>
                <div className="home-favorites">
                  <div className="home-favorites-header">
                    <p>דוחות מועדפים</p>
                    {authData.auth.user.favorites.length > 3 && (
                      <Link to="/favorites" className="show-all">
                        <p>הצג הכל </p>
                        <i className="fa-solid fa-angle-left"></i>
                      </Link>
                    )}
                  </div>
                  {favoritesReports.length > 0 ? (
                    <div className="home-favorites-reports">
                      {favoritesReports.map((report) => {
                        return (
                          <a
                            href={report.url}
                            key={report.id}
                            onClick={(e) => {
                              e.preventDefault();
                              setIframeSrc(report.url);
                            }}
                          >
                            <ReportBox
                              data={report}
                              favorite={favoritesIds.includes(report.id)}
                              handleFavorites={handleFavorites}
                            />
                          </a>
                        );
                      })}
                    </div>
                  ) : (
                    <p className="noResults">אין תוצאות</p>
                  )}
                </div>
                <div className="home-favorites">
                  <div className="home-favorites-header">
                    <p>דוחות אחרונים</p>
                    {authData.auth.user.latest.length > 3 && (
                      <Link to="/latest" className="show-all">
                        <p>הצג הכל </p>
                        <i className="fa-solid fa-angle-left"></i>
                      </Link>
                    )}
                  </div>
                  {latestReports.length > 0 ? (
                    <div className="home-favorites-reports">
                      {latestReports.map((report, index) => (
                        <div
                          key={report.id}
                          onClick={() => {
                            setIframeSrc(report.url);
                          }}
                        >
                          <ReportBox
                            data={report}
                            key={index}
                            favorite={favoritesIds.includes(report.id)}
                            handleFavorites={handleFavorites}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="noResults">אין תוצאות</p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
