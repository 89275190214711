import { useEffect, useMemo, useState } from "react";

const AccordingToTime = () => {
    const currentTime = useMemo(() => new Date().getHours(),[])
    const [greetingText, setGreetingText] = useState('');

    useEffect(() => {
        if (currentTime < 12) {
            setGreetingText("בוקר טוב"); 
          } else if (currentTime < 18) {
            setGreetingText("צהריים טובים"); 
          } else {
            setGreetingText("ערב טוב"); 
        }
    },[])

    return (
        <p>{greetingText}</p>
    )
}

export default AccordingToTime;