import Api from './Api';

class TreeService {
    async tree() {
        try {
            const {
                data: {
                    data: { tree },
                },
            } = await Api.get('tree');

            return tree;
        } catch (e) {
            return false;
        }
    }
}

export default new TreeService();
