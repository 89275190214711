import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router';

const Accordion = ({ data, activeIndex }) => {
	const navigate = useNavigate();
	const { reports } = useParams();
	const [activeChild, setActiveChild] = useState(false);

	const findSlug = () => {
		setActiveChild(!!data.children.find((child) => child.slug == reports));
	};

	const [openAccordion, setOpenAccordion] = useState(false);

	useEffect(() => {
		findSlug();
	}, [reports, data]);

	const handleAccordionClick = () => {
		if (data.children.length === 0) {
			navigate(`/${data.slug}`);
			return;
		}

		setOpenAccordion(!openAccordion);
	};

	return (
		<div className="accordion noselect" aria-expanded={openAccordion}>
			<NavLink
				to={`/${data.slug}`}
				className="accordion-title-wrapper"
				style={({ isActive }) => ({
					backgroundColor: isActive ? '#eaeaea' : 'unset',
					fontWeight: isActive ? 'bold' : 'unset',
				})}
			>
				<p
					className={
						activeChild
							? 'active-accodrion-title'
							: 'accodrion-title'
					}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						handleAccordionClick();
					}}
				>
					{data.title}
					{data.children.length > 0 && (
						<i
							className={
								openAccordion
									? 'fa-solid fa-caret-down upsidedown'
									: 'fa-solid fa-caret-down'
							}
						></i>
					)}
				</p>
			</NavLink>
			<div className="openLinks" aria-expanded={!openAccordion}>
				{data.children.map((link, index) => {
					return (
						<Link
							key={index}
							to={`/${link.slug}`}
							className={
								data.children[index].slug == reports
									? 'accordion-link active-link'
									: 'accordion-link'
							}
						>
							{link.title}
							{data.children[index].slug == reports && (
								<div className="underline-link"></div>
							)}
						</Link>
					);
				})}
			</div>
		</div>
	);
};

export default Accordion;
