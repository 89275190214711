import { LOGIN, LOGOUT, UPDATE_FAVORITES, UPDATE_FIRST_LOGIN, UPDATE_LAST_REPORTS } from "../actions/authActions"
//TODO ADD IS LOGIN
//ADD USER OBJ
const initialState = {
    auth: {
        loggedIn: false,
        user: null,
        tree: [],

    },

}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                auth: { loggedIn: action.loggedIn, user: action.user, tree: action.tree }
            }
        
        case LOGOUT: 
            return {
                ...initialState
            }

        case UPDATE_FIRST_LOGIN: 
            return {
                ...state,
                auth: { ...state.auth, user: {...state.auth.user, is_first_login: action.payload} }
            }

        case UPDATE_FAVORITES:
            const auth = { ...state.auth };
            return {
                ...state,
                auth: {
                    ...auth,
                    user: {
                        ...auth.user,
                        favorites: action.favorites,
                    }
                }
            }

        case UPDATE_LAST_REPORTS: {
            const auth = { ...state.auth }
            return {
                ...state,
                auth: {
                    ...auth,
                    user: {
                        ...auth.user,
                        latest: action.lastReports
                    }
                }
            }
        }

        default: return state
    }
}

export default authReducer