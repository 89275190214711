import React from 'react'

const IframePage = ({ iframeSrc, closeIframe, sideNavOpen }) => {
    return (
        <div className={sideNavOpen ? 'iframePage shrink-page' : 'iframePage'}>
            <div className='close-iframe' onClick={() => { closeIframe() }}>&times;</div>
            <iframe src={iframeSrc} className="iframe" />
        </div>
    )
}

export default IframePage