import React from 'react';
import Moment from "react-moment";
import moment from "moment";
import 'moment/locale/he';
moment().locale('he')
const HeaderDate = () => {
    return (
        <Moment date={Date.now()} format={'LL'} locale={'he'} />
    )
}

export default HeaderDate