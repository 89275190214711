import moment from 'moment'
import emptyStar from '../assets/star-empty.svg'
import fullStar from '../assets/star-full.svg'

const ReportRow = ({ data, favorite, setIframeSrc, handleFavorites }) => {
    return (
        <div className='report-row' onClick={() => {
            setIframeSrc(data.url)
        }}>
            <div className='row-right'>
                <div className='row-favorite-wrapper noselect' onClick={(e) => {
                    e.stopPropagation();
                    handleFavorites(data.id)
                }}>
                    {favorite ?
                        <img src={fullStar} alt='' />
                        :
                        <img src={emptyStar} alt='' />
                    }
                </div>
                <div className='row-titles'>
                    {data.name && <p className='row-title'>{data.name}</p>}
                    <p className='row-extra'>{data.description}</p>
                </div>
            </div>
            <div className='row-left'>
                <div className='row-date'>
                    {moment(data.date).format('DD.MM.YYYY')}
                </div>
            </div>
        </div>
    )
}

export default ReportRow