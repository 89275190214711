export const getReports = (slug, tree) => {
	let findRes = find(slug, tree);
	return findRes;
};

function find(slug, array) {
	let result;
	array.some(
		(o) =>
			(o.slug === slug && (result = o)) ||
			(result = find(slug, o.children || [])),
	);
	return result;
}

export const searchReports = (search, tree) => {
	let results = [];
	for (let i = 0; i < tree.length; i++) {
		if (tree[i].is_last && tree[i].reports) {
			results = results.concat(
				searchInReportArr(search, tree[i].reports),
			);
		} else {
			for (let j = 0; j < tree[i].children.length; j++) {
				for (let x = 0; x < tree[i].children[j].children.length; x++) {
					results = results.concat(
						searchInReportArr(
							search,
							tree[i].children[j].children[x].reports,
						),
					);
				}
			}
		}
	}
	return results;
};

function searchInReportArr(search, arr) {
	let res = [];
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].title && arr[i].title.includes(search)) {
			res.push(arr[i]);
		}
	}
	return res;
}
