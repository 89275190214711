import AppRouter from './routes/AppRouter';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import authReducer from './store/authReducer';

const reducers = combineReducers({ auth: authReducer });
const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </div>
  );
}

export default App;
