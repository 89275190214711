import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { updateFirstLoginAction } from "../actions/authActions";
import mbi from '../assets/deflog.webp'
import eyeIcon from '../assets/openEye.svg'
import Loader from "../components/Loader";
import { services } from "../services";

const CreatePassword = () => {
    const [password, setPassword] = useState("")
	const [repeatPassword, setRepeatPassword] = useState("")
    const [openEye, setOpenEye] = useState(false)
    const [secondopenEye, setSecondopenEye] = useState(false)
    const [error, setError] = useState(false)
    const [errMsg, setErrMsg] = useState("")
	const [updated, setUpdated] = useState(false)
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const createPassoword = async (e) => {
        e.preventDefault()
        if (password === "") {
            setError(true)
            setErrMsg("חובה למלא סיסמה")
            return
        }
		if (password !== repeatPassword) {
			setError(true)
			setErrMsg("הסיסמא אינה תואמת לאימות")
			return
		}
        try {
            setErrMsg("")
            setError(false)
			setLoading(true)
            const result = await services.auth.createPassword(password);
			setLoading(false)
            if (!result) {
                setError(true)
                setErrMsg("אירעה שגיאה בשרת")
                return
            }
            if (result) {
				setUpdated(true)
				dispatch(updateFirstLoginAction(false))
                setTimeout(() => {
                    navigate("/")
                }, 3000)
            }
        } catch (err) {
            setError(true)
            setLoading(false)
            
            if(err.response.data.error.includes("same password")) {
                return setErrMsg("לא ניתן להשתמש בסיסמה קודמת")
            }

            setErrMsg("אירעה שגיאה בשרת")
        }
        
    }
	return (
		<div className="LoginPage">
			<div className="loginContent">
				<div className="login-logo-wrapper">
					<img src={mbi} alt="mbi" />
				</div>
				<form
					className="login-box"
					onSubmit={(e) => {
						createPassoword(e);
					}}
				>
					<p className="login-header">החלפת סיסמה</p>
					<div className="inputs-wrapper">
					<div style={{ 'backgroundColor': password ? 'white' : '#f5f5f5 ' }} className={error ? 'input-wrapper password-wrapper error-input' : 'input-wrapper password-wrapper'}>
                                    <input type={openEye ? 'text' : 'password'} className='login-input' placeholder='סיסמא' value={password} onChange={(e) => {
                                        setPassword(e.target.value)
                                        setError(false)
                                        setErrMsg('')
                                    }} />
                                    <img onClick={() => {
                                        setOpenEye(!openEye)
                                    }} className={openEye ? 'openEye' : 'closeEye'} src={eyeIcon} alt='' />
                                </div>
					</div>
					<div className="inputs-wrapper">
					<div style={{ 'backgroundColor': password ? 'white' : '#f5f5f5 ' }} className={error ? 'input-wrapper password-wrapper error-input' : 'input-wrapper password-wrapper'}>
                                    <input type={secondopenEye ? 'text' : 'password'} className='login-input' placeholder='אימות סיסמא' value={repeatPassword} onChange={(e) => {
                                        setRepeatPassword(e.target.value)
                                        setError(false)
                                        setErrMsg('')
                                    }} />
                                    <img onClick={() => {
                                        setSecondopenEye(!secondopenEye)
                                    }} className={secondopenEye ? 'openEye' : 'closeEye'} src={eyeIcon} alt='' />
                                </div>
						{error && (
							<p className="errorText">
								{errMsg}
							</p>
						)}
					</div>
					<button type="submit" className="login-btn">
						עדכן
					</button>
					{
						loading && <Loader />
					}
                    {
                        updated && 
						<>
                        <p>סיסמה הוחלפה בהצלחה</p>
						<p>הינך מועבר לדף הבית</p>
						</>
                    }
				</form>
			</div>
		</div>
	);
};

export default CreatePassword;
