import React, { useEffect } from 'react';
import Accordion from './Accordion';

const OpenSideNav = ({ isOpen, data, onCloseClick }) => {
  //console.log("OpenSideNav", data)

  useEffect(() => { }, [data]);

  return (
    <div className={isOpen ? 'openSideNav openSideNav-open' : 'openSideNav'}>
      <div className="openNavTitle">
        {data.title}
        <div className="closeSideNav" onClick={onCloseClick}>
          <i className="fa-solid fa-angle-right"></i>
        </div>
      </div>
      {data.children && (
        <div className="second-level">
          {data.children.map((item, index) => (
            <Accordion key={index} data={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default OpenSideNav;
