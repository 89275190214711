import moment from 'moment';
import { useState } from 'react';
import emptyStar from '../assets/star-empty.svg';
import fullStar from '../assets/star-full.svg';

const ReportBox = ({
  data,
  favorite,
  handleFavorites,
  showBreadcrumbs = true,
}) => {
  const imageURL = process.env.REACT_APP_PUBLIC_URL;
  const [hover, setHover] = useState(false);

  return (
    <div
      className="reportBox"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <div
        className={
          hover
            ? 'emptyStarWrapper noselect hover-favorite'
            : 'emptyStarWrapper noselect'
        }
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleFavorites(data.id);
        }}
      >
        {favorite ? (
          <img src={fullStar} alt="" />
        ) : (
          <img src={emptyStar} alt="" />
        )}
      </div>

      <div className="reportBox-top">
        <div className={hover ? 'hover-img showHover' : 'hover-img'}>
          {/* <button className='editBtn'>עריכה</button> */}
        </div>
        <div className="iframe-review">
          {data.img ? (
            <img src={`${imageURL}${data.img}`} alt={data.name} />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="reportBox-bottom">
        <p className="box-title">{data.name}</p>
        <p className="box-date">{moment(data.date).format('DD.MM.YYYY')}</p>
      </div>
      {data.breadcrumbs && showBreadcrumbs && (
        <div className="reportBox-breadCrumbs">
          <p className="box-breadcrumbs">
            {data.breadcrumbs.map((breadCrumb, i) => (
              <>
                <span>{breadCrumb}</span>
                <span>{data.breadcrumbs.length - 1 !== i && ' > '}</span>
              </>
            ))}
          </p>
        </div>
      )}
    </div>
  );
};

export default ReportBox;
