import React from 'react';
import { Link } from 'react-router-dom';

const NavIcon = ({ data, onIconClick, index, active }) => {
  const imageURL = process.env.REACT_APP_PUBLIC_URL;
  // console.log("NavIcon", data)
  // let imgName = active ? data.icon + '-active' : data.icon

  return (
    <div className="sideIcon">
      {!data.children.length ? (
        <Link
          className="side-link"
          to={`/${data.slug}`}
          onClick={() => {
            onIconClick(index);
          }}
        >
          <div className={`navLinkWrapper ${active ? 'active' : ''}`}>
            {data.icon && data.icon.length > 0 && (
              <img src={`${imageURL}${data.icon}`} alt={data.title} />
            )}
            <p className="iconTitle">{data.title}</p>
          </div>
        </Link>
      ) : (
        <div
          onClick={() => {
            onIconClick(index);
          }}
        >
          <div className={`navLinkWrapper ${active ? 'active' : ''}`}>
            {data.icon && data.icon.length > 0 && (
              <img src={`${imageURL}${data.icon}`} alt={data.title} />
            )}
            <p className="iconTitle">{data.title}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavIcon;
