import { useEffect } from "react"
import { useNavigate } from "react-router";
import { logoutAction } from "../actions/authActions";
import { services } from "../services";
import { useDispatch } from 'react-redux';

const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        services.auth.removeToken();

        dispatch(logoutAction());

        navigate("/")
    },[])

    return (
        <div></div>
    )
}

export default Logout